import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button, cn } from '@divlab/divanui';
import { IconBasket, IconCheckBasket } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import { useHasInCart } from '@Queries/Cart';
import { useMeta } from '@Queries/Meta';
import useNavigation from '@Navigation/useNavigation';
import ProductLike from '@Components/ProductLike';
import ProductTags from '@Components/ProductTags';
import GalleryProductPreviews from '@Components/GalleryProductPreviews';
import ExpiredPrice from '@Components/ExpiredPrice';
import ReadySolutions from '@Components/ReadySolutions';
import Characteristics from '@Components/Characteristics';
import CreditTab from '@Components/CreditTab';
import LinearTag from '@Promo/elems/LinearTag';
import PreorderTab from '@Components/PreorderTab';
import SmartRender from '@Components/SmartRender';
import SEOBuyButton from '@Components/SEOBuyButton';
import ProductPreview from '@Components/ProductPreview';
import Link from '@Navigation/Link';
import useMedias from '@Hooks/useMedias';
import useRenderType from '@Hooks/useRenderType';
import useRequest from '@Hooks/useRequest';
import useTranslation from '@Queries/useTranslation';
import getProductName from '@Utils/getProductName';
import ProductSelect from './elements/ProductSelect';
import HiddenPrice from './elements/HiddenPrice';
import SEOMetaData from './elements/SEOMetaData';
import SolutionsTag from './elements/SolutionsTag';
import PenSvg from './pen.svg';
import Img from '@UI/Img';
import useDeps from '@Contexts/DI/useDeps';
import styles from './ProductCard.module.css';

import type {
  ProductData,
  GroupPlace,
  ProductParameterGroupData,
  CustomizeData,
} from '@Types/Product';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface CardDetailsData {
  defaultCharacteristics?: ProductParameterGroupData[];
  secondaryCharacteristics?: ProductParameterGroupData[];
  variantsPlace?: GroupPlace;
}

export interface ProductCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  view?: 'mini';
  hasReadySolutions?: boolean;
  hiddenPrice?: boolean;
  slug?: string;
  imageLazyLoading?: boolean;
  tagAnimation?: boolean;
  onClickProductLink?: (e: MouseEvent) => void;
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const {
    className,
    product,
    view,
    hiddenPrice,
    hasReadySolutions,
    slug,
    imageLazyLoading,
    tagAnimation,
    onClickProductLink,
    ...restProps
  } = props;
  const [currentProduct, setCurrentProduct] = useState<ProductData>(product);
  const [hovered, setHovered] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const { isSSR, isCSR } = useRenderType();
  const { isMobileM, isMobileXS } = useMedias();
  const { t } = useTranslation();
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: false });
  const isViewMini = view === 'mini';
  const [firstImage] = currentProduct.images;
  const isCreditAvailable = !!product.credit;
  const hasInCart = useHasInCart({ productId: currentProduct.id });
  const { country } = useRequest();
  const characteristicRef = useRef<HTMLDivElement>(null);
  const isUzb = country === 'UZB';
  const { openPage } = useNavigation();
  const { analytics } = useDeps();
  const meta = useMeta();
  const { region } = meta.data;

  const products = [product];
  const productIdWithColor = products.find((prod) => prod.readySolutions)?.id;
  const productIdWithCustomize = products.find((custom) => custom.customize)?.id;
  const isCustomizedProduct = productIdWithCustomize === product?.id;
  const isProductWithColors = productIdWithColor === product?.id;

  const mainCredit = useMemo(() => {
    if (!isCreditAvailable) return null;
    if (!Array.isArray(currentProduct.credit)) return currentProduct.credit;

    const availableCredit = currentProduct.credit.find((credit) => credit.isMain);

    if (availableCredit) {
      return availableCredit;
    }
  }, [currentProduct.credit, isCreditAvailable]);

  const parameters = useMemo<CardDetailsData>(() => {
    return currentProduct.parameterGroups.reduce(
      (acc, item) => {
        if (item.id === currentProduct.variants?.parameterGroupId) {
          if (currentProduct.variants?.values.length < 2 && item.place === 'secondary') {
            acc.secondaryCharacteristics.push(item);
          }
          acc.variantsPlace = item.place;
        } else {
          if (item.place === 'default') acc.defaultCharacteristics.push(item);
          if (item.place === 'secondary') acc.secondaryCharacteristics.push(item);
        }
        return acc;
      },
      {
        defaultCharacteristics: [],
        secondaryCharacteristics: [],
        variantsPlace: null,
      },
    );
  }, [currentProduct]);

  const hasDefaultCharacteristics = parameters.defaultCharacteristics.length > 0;
  const hasSecondaryCharacteristics = parameters.secondaryCharacteristics.length > 0;

  const needSecondaryPlaceholder =
    hasReadySolutions &&
    !product.readySolutions?.colors.length &&
    parameters.variantsPlace === 'secondary';

  const creditTabText = useMemo(() => {
    if (!mainCredit) return '';

    if (mainCredit.type === 'halvaPartial') {
      return isMobileM ? t('ui.for') : '';
    }

    if (country === 'BLR') {
      return isMobileXS ? t('ui.range.from') : t('ui.installment-from');
    }

    return t('ui.range.from');
  }, [mainCredit, isMobileM, isMobileXS, country, t]);

  const handleBuy = useCallback(
    (e: MouseEvent) => {
      if (onClickProductLink) onClickProductLink(e);

      if (hasInCart) {
        openPage({ url: `${region.url}/order/check` });
        return;
      }

      ModalWindows.open('Cart', {
        products: [
          {
            isModular: false,
            shopProductId: currentProduct.id,
          },
        ],
        analyticsLabel: 'Листинг',
      });
    },
    [currentProduct.id, hasInCart, onClickProductLink, openPage, region.url],
  );

  const handleChangeProduct = useCallback((newProduct: ProductData) => {
    setCurrentProduct(newProduct);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  useEffect(() => {
    setCurrentProduct(product);
  }, [product]);

  useEffect(() => {
    if (!characteristicRef?.current) return;

    const rect = characteristicRef.current.getBoundingClientRect();

    setHeight(rect.height);
  }, [product]);

  const productCustomize = product?.customize;
  const customizeTranslate = `ui.customize.type-${productCustomize?.type}`;

  const handleClickSolutionTag = useCallback(
    (e: MouseEvent, type: string, customize?: CustomizeData) => {
      if (type === 'mobile') {
        const solutionTagModalData = {
          title: t(`${customizeTranslate}.modal-data.title`),
          description: t(`${customizeTranslate}.modal-data.description`),
          button: {
            text: t(`${customizeTranslate}.modal-data.button.text`),
            link: customize.link,
          },
          type: customize.type,
        };
        ModalWindows.open('SolutionTag', { tag: solutionTagModalData });
      }

      if (type === 'desktop' && onClickProductLink) onClickProductLink(e);

      analytics.dispatchEvent('labelTag.clickLabelTag', { product });
    },
    [analytics, product, customizeTranslate, onClickProductLink, t],
  );

  const productCustomizeClassNameCheck =
    (isCustomizedProduct && productCustomize?.type === 2) || productCustomize?.type === 3;

  return (
    <div
      {...restProps}
      data-testid='product-card'
      className={cn(
        styles.productCard,
        {
          [styles.hasExtraDiscount]: product.extraDiscount,
          [styles.viewMini]: isViewMini,
          [styles.uzb]: isUzb,
        },
        className,
      )}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.box} />

      <div className={styles.container}>
        <div className={styles.containerImage}>
          {inView ? (
            <GalleryProductPreviews
              images={currentProduct.images.map((item) => ({
                ...item,
                alt: getProductName(currentProduct),
              }))}
              link={currentProduct.link}
              orientation={currentProduct.orientation}
              imageLazyLoading={imageLazyLoading}
              onClick={onClickProductLink}
            />
          ) : (
            <ProductPreview
              image={{ ...firstImage, alt: getProductName(currentProduct) }}
              link={currentProduct.link}
              orientation={currentProduct.orientation}
              onClick={onClickProductLink}
            />
          )}
          <SmartRender countries={['RUS']} condition={!isMobileM && !!product.isPreorder}>
            <Link to={currentProduct.link} onClick={onClickProductLink}>
              <PreorderTab className={styles.preorderTab} />
            </Link>
          </SmartRender>
          {(isCreditAvailable || product.extraDiscount) && !isMobileM && (
            <Link to={currentProduct.link} onClick={onClickProductLink}>
              {product.extraDiscount && !product.hasKitActivity && (
                <LinearTag
                  type='extra'
                  className={cn(styles.extraSaleTag, {
                    [styles.withCredit]: isCreditAvailable,
                  })}
                  animation={tagAnimation}
                />
              )}

              {product.hasKitActivity && (
                <LinearTag
                  type='kits'
                  className={cn(styles.extraSaleTag, {
                    [styles.withCredit]: isCreditAvailable,
                  })}
                  animation={tagAnimation}
                />
              )}
              {mainCredit && (
                <CreditTab
                  className={styles.creditTab}
                  credit={mainCredit}
                  text={creditTabText}
                  textEnd={mainCredit?.type === 'halvaPartial' ? ' x 4' : mainCredit?.prefix}
                  view='catalog'
                />
              )}
            </Link>
          )}
          <div className={styles.actions}>
            <ProductLike className={styles.action} product={currentProduct} label='Листинг' />
          </div>
          {isCustomizedProduct && (
            <div className={styles.solutionTagMobile}>
              <Img
                onClick={(e) => handleClickSolutionTag(e, 'mobile', productCustomize)}
                className={styles.penIcon}
                src={PenSvg}
                alt=''
              />
            </div>
          )}
          {currentProduct.tags?.length > 0 && (
            <ProductTags className={styles.tags} tags={currentProduct.tags} />
          )}
        </div>

        <div className={styles.info}>
          <div className={styles.infoContent}>
            <Link
              className={cn(
                styles.name,
                'ProductName',
                currentProduct.status === 'Disable' && 'ForCount',
                currentProduct.status === 'Active' && 'ActiveProduct',
              )}
              to={currentProduct.link}
              onClick={onClickProductLink}
            >
              <span itemProp='name'>{getProductName(currentProduct)}</span>
            </Link>

            <SEOMetaData currentProduct={currentProduct} />

            {hiddenPrice ? (
              <HiddenPrice actualPrice={currentProduct.price.actual} mini={isViewMini} />
            ) : (
              <>
                {/* Требование SEO чтобы от сервера всегда приходила цена,
              и только на клиенте изменялась на Нет в наличии */}
                {isCSR && !currentProduct.isActive ? (
                  <div className={styles.outOfStock}>{t('ui.not-available')}</div>
                ) : (
                  <>
                    <div className={styles.wrapperPrice}>
                      <ExpiredPrice product={currentProduct} mini={isViewMini} />
                      {isMobileM && (
                        <>
                          {hasInCart ? (
                            <Button
                              view='circle'
                              theme='transparent'
                              className={styles.buyMobile}
                              onClick={handleBuy}
                              data-testid='icon-buy-button-added'
                            >
                              <IconCheckBasket className={styles.iconBasket} />
                            </Button>
                          ) : (
                            <Button
                              view='circle'
                              onClick={handleBuy}
                              className={styles.buyMobile}
                              data-testid='icon-buy-button'
                            >
                              <IconBasket className={styles.iconBasket} theme='light' />
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    {isMobileM && product.isPreorder && (
                      <Link to={currentProduct.link} onClick={onClickProductLink}>
                        <PreorderTab className={styles.preorderTab} />
                      </Link>
                    )}
                    {isCreditAvailable && isMobileM && (
                      <Link to={currentProduct.link} onClick={onClickProductLink}>
                        {product.hasKitActivity || product.extraDiscount ? (
                          <LinearTag
                            type={product.hasKitActivity ? 'kits' : 'extra'}
                            className={styles.extraSaleTag}
                            animation={tagAnimation}
                          />
                        ) : (
                          <>
                            {mainCredit && (
                              <CreditTab
                                className={styles.creditTab}
                                credit={mainCredit}
                                text={creditTabText}
                                textEnd={
                                  mainCredit?.type === 'halvaPartial' ? ' x 4' : mainCredit?.prefix
                                }
                                view='catalog'
                              />
                            )}
                          </>
                        )}
                      </Link>
                    )}
                  </>
                )}
              </>
            )}

            <div
              className={cn({
                [styles.readySolutionsContainer]: isProductWithColors && isCustomizedProduct,
                [styles.readySolutionsContainerSpace]: productCustomizeClassNameCheck,
              })}
            >
              {hasReadySolutions && isProductWithColors && (
                <ReadySolutions
                  className={styles.fabricsWrapper}
                  readySolutions={product.readySolutions}
                  view={view}
                  slug={slug}
                  product={currentProduct}
                  onChangeProduct={handleChangeProduct}
                  onClickColor={onClickProductLink}
                />
              )}

              {isCustomizedProduct && (
                <div
                  className={cn(styles.solutionTagDesktop, {
                    [styles.solutionTagDesktopWithoutColor]: !isProductWithColors,
                  })}
                >
                  <Link
                    to={productCustomize.link}
                    onClick={(e) => handleClickSolutionTag(e, 'desktop')}
                  >
                    <SolutionsTag
                      tag={{
                        title: productCustomize.type === 1 ? '' : t(`${customizeTranslate}.title`),
                        icon: PenSvg,
                      }}
                    />
                  </Link>
                </div>
              )}
            </div>

            {parameters.variantsPlace === 'default' && (
              <ProductSelect
                className={styles.sizes}
                product={currentProduct}
                onClickItem={onClickProductLink}
              />
            )}
          </div>
          {hasDefaultCharacteristics && (
            <div
              className={cn(styles.characteristicsWrapper, { [styles.isHover]: hovered })}
              ref={characteristicRef}
            >
              <Characteristics
                className={styles.parameterGroups}
                groups={parameters.defaultCharacteristics}
                product={currentProduct}
              />
            </div>
          )}
        </div>

        {/* Обязательно включаем в SSR, но на клиенте рендерим только во вьюпорте, чтобы улучшить производительность */}
        {(isSSR || (inView && !isMobileM)) && (
          <div
            data-testid='product-card-2'
            className={cn(styles.additionalInfo, {
              [styles.placeholder]: needSecondaryPlaceholder,
            })}
            style={{ marginTop: `-${height}px` }}
          >
            {parameters.variantsPlace === 'secondary' &&
              currentProduct.variants?.values.length > 1 && (
                <ProductSelect
                  className={styles.sizes}
                  product={currentProduct}
                  onClickItem={onClickProductLink}
                />
              )}

            {(hasDefaultCharacteristics || hasSecondaryCharacteristics) && (
              <div className={styles.characteristicsWrapper}>
                {hasDefaultCharacteristics && (
                  <Characteristics
                    className={styles.parameterGroups}
                    groups={parameters.defaultCharacteristics}
                    product={currentProduct}
                  />
                )}
                {hasSecondaryCharacteristics && (
                  <Characteristics
                    groups={parameters.secondaryCharacteristics}
                    product={currentProduct}
                  />
                )}
              </div>
            )}
            <SEOBuyButton
              data-testid='buy-button'
              className={styles.buy}
              product={currentProduct}
              onClick={handleBuy}
            />
            {isCSR && (
              <div className={styles.moreWrapper}>
                <Link
                  className={styles.more}
                  to={currentProduct.link}
                  view='secondary'
                  underlined
                  onClick={onClickProductLink}
                >
                  {t('ui.more-about-product')}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ProductCard);
